.Box{
    width: 100%;

    // Range Input
    .RangeInput {
        width: 100px;
    }

    // Report Label
    .Label {
        padding-bottom: 0;
        padding-right: .5rem;
        font-size: var(--text-size-x3);
    }
}