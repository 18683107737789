$connectionColumnWidth: 24px;

.Box{
	width: calc((100% - #{$connectionColumnWidth}) / 2);
	border: 1px solid var(--alternative-secondary-bg-color);
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.06), 0 1px 2px rgba(0, 0, 0, 0.06);
	border-radius: 10px;
	background: var(--primary-bg-color);
}

.BoxHeader{
	background: var(--secondary-bg-color);
	border-radius: 10px 10px 0 0;
	height: 44px;
}

.Row{
	padding: 7px 15px;
}

.DeleteBtn{
	width: 60px;
}

.ConnectionBox{
	width: 25px;
	padding-top: 51px;
}

.ConnectionRow{
	height: 50px;
	width: 100%;

	:global(.fill){
		fill: var(--secondary-bg-icons-color);
	}

	&.Active{
		:global(.fill){
			fill: var(--primary-bg-icons-color);
		}
	}
}