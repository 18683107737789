.MainMenu {

    // Menu Item
    .MenuItem {
        padding: 0 1rem;
        margin: 0 1rem 3px 1rem;
        height: 35px;
        text-decoration: none;
        color: var(--alternative-secondary-text-color);
        transform: translateY(2px);
        white-space: nowrap;

        // Active State
        &.Active {
            color: var(--alternative-primary-text-color);
            border-bottom: 2px solid var(--alternative-primary-text-color);
        }
    }
}