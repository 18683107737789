.NotificationsWrapper{
	position: relative;
}

.Notifications{
	cursor: pointer;

	// Icon
	.Icon{
		:global(.fill){
			fill: var(--secondary-bg-icons-color);
		}

		&.HasNewNotify{
			position: relative;

			&:before{
				content: '';
				display: block;
				background-color: var(--danger-text-color);
				width: 8px;
				height: 8px;
				position: absolute;
				bottom: 3px;
				right: 1px;
				border-radius: 8px;
			}
		}

		&.Active{
			:global(.fill){
				fill: var(--alternative-primary-icons-color);
			}
		}
	}
}

.DropdownLayer{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 6;
}

.Dropdown{
	position: absolute;
	top: 65px;
	right: -1rem;
	border: 1px solid var(--sidebar-primary-text-color);
	background: var(--sidebar-primary-color);
	z-index: 7;
	width: 220px;
	border-radius: 10px;

	&:before,
	&:after {
		content: '';
		display: block;
		width: 0;
		height: 0;
		border-left: 12px solid transparent;
		border-right: 12px solid transparent;
		border-bottom: 12px solid var(--sidebar-primary-color);
		position: absolute;
		top: -12px;
		right: 1rem;
	}

	&:before{
		border-bottom-color: var(--sidebar-primary-text-color);
		top: -13px;
	}

	&:after{
		top: -11.5px;
	}
}

.DropdownTitle{
	color: var(--primary-text-color);
}

.NotifyList{
	max-height: 230px;
	overflow-y: auto;
}

.Notify{
	cursor: pointer;
	// border-bottom: 1px solid var(--secondary-bg-icons-color);

	// &:last-child {
	// 	border-bottom: none;
	// }

	.Icon {
		:global(.fill){
			fill: var(--secondary-bg-icons-color);
		}
	}

	.NotifyData{
		// overflow: hidden;
		max-width: 132px;
	}

	.NotifyLabel{
		color: var(--primary-text-color);
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	.NotifyText{
		color: var(--sidebar-text-color);
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	.NotifyTime{
		color: var(--sidebar-text-color);
	}

	.Mark{
		color: var(--sidebar-text-color);
	}

	// &.New{
	// 	// background-color: var(--secondary-bg-color);

	// 	.NotifyLabel{
	// 		color: var(--alternative-primary-color);
	// 	}
	// }

	// Expanded
	&.Expanded {

		.NotifyText{
			white-space: normal;
		}
	}

	// Hover Message
	&:hover {
		background-color: var(--secondary-bg-color);
	}
}