.ProjectsList {

    .Item {
        height: 164px;
        flex-basis: 330px;
        max-width: 330px;
        border: 1px solid var(--disable-bg-color);
        background: var(--secondary-gradient-color);
        border-radius: 10px;
        color: var(--sidebar-text-color);
        cursor: pointer;

        .Name {
            padding: .5rem;
        }

        .Description {
            padding: .5rem;
            color: var(--sidebar-text-color);

            .Text {
                --lh: 1.2rem;
                line-height: var(--lh);
                max-height: calc(var(--lh) * 4);
            }
        }

        .Controls{
            margin: 0 1px;
            width: 100%;

            &:first-child{
                margin-left: 0;
            }

            &:last-child{
                margin-right: 0;
            }
        }

        .Button {
            height: 43px;
            border-radius: 0;
            border: none;

            // Play
            &.Play {
                border-radius: 0 0 0 10px;
                border-left: none;
                border-bottom: none;
                color: var(--secondary-bg-icons-color);

                &:hover {
                    color: var(--alternative-primary-icons-color);
                }
            }

            // View
            &.View {
                border-radius: 0 0 0 0;
                border-bottom: none;
            }

            // Delete
            &.Delete {
                border-radius: 0 0 10px 0;
                border-right: none;
                border-bottom: none;
            }
        }

        &:hover{
            border: 1px solid var(--alternative-primary-color);

            .Description {
                color: var(--primary-text-color);
            }
        }
    }
}