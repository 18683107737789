.Table {
    // grid-template-rows: 60px minmax(0, 1fr);
    height: 100%;
    background-color: var(--primary-bg-color);

    // Body
    .Body {
        grid-auto-rows: 45px;


        // Body Row
        .BodyRow {
            // background-color: blue;
            grid-template-columns: 50px repeat(8, minmax(0, 1fr));
        }
    }

    * {
        box-shadow: none;
    }

    // Theme
    --rdg-color: var(--sidebar-text-color) !important;
    --rdg-border-color: var(--primary-bg-color) !important;
    --rdg-summary-border-color: #555 !important;
    --rdg-background-color: var(--primary-bg-color) !important;
    --rdg-header-background-color: var(--primary-bg-color) !important;
    --rdg-row-hover-background-color: var(--primary-bg-color) !important;
    --rdg-row-selected-background-color: #1a73bc !important;
    --row-selected-hover-background-color: #1768ab !important;
    --rdg-checkbox-color: #94cfff !important;
    --rdg-checkbox-focus-color: #c7e6ff !important;
    --rdg-checkbox-disabled-border-color: #000 !important;
    --rdg-checkbox-disabled-background-color: #333 !important;
}
