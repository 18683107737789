// Flow Top Bar
.FlowTopBar {
	background-color: var(--secondary-bg-color);
}

// Flow Header
.FlowHeader {
	background-color: var(--primary-bg-color);
	position: relative;
	// min-height: 56px;
	border-radius: 10px 10px 0 0;

	.TitleSide {
		height: 40px;
		width: 4px;
		background: var(--alternative-primary-color);
		position: absolute;
		top: 6px;
		left: 0;
	}

	// Title
	.Title {
	}

	// Text
	.Text {
		color: var(--sidebar-text-color);
	}
}
