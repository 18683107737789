.RulesList {

    .Item {
        height: 164px;
        flex-basis: 330px;
        max-width: 330px;
        border: 1px solid var(--disable-bg-color);
        background: var(--secondary-gradient-color);
        border-radius: 10px;
        margin-bottom: 2rem;
        margin-right: 2rem;
        color: var(--sidebar-text-color);
        cursor: pointer;

        .Name {
            white-space: nowrap;
            padding: .5rem;
			width: 100%;
        }
        .Participants {
            white-space: nowrap;
            padding: .5rem;
        }

        .ScoreBlock{
            .Score{
                margin-left: 0.25rem;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 40px;
                width: 27px;
                height: 27px;
                background: var(--alternative-secondary-bg-color);

                span{
                    font-size: 10px;
                    line-height: 12px;
                    font-weight: 700;
                    color: var(--sidebar-primary-text-color);
                }
            }
        }

		.DescriptionBlock{
			--lh: 1.2rem;
			line-height: var(--lh);
			max-height: calc(var(--lh) * 4 + 1rem);
			padding: .5rem;

			.Description {
				width: 100%;
				color: var(--sidebar-text-color);
			}
		}

        .Controls{
            margin: 0 1px;
            width: 100%;

            &:first-child{
                margin-left: 0;
            }

            &:last-child{
                margin-right: 0;
            }
        }

        // View
        .View {
            border-radius: 0 0 0 0;
            border-bottom: none;

            &.LeftBtn{
                border-radius: 0 0 0 10px;
            }
        }

        .Toggle {
            height: 43px;
            background-color: var(--secondary-button-bg-color);

            &:hover{
                background-color: var(--secondary-button-hover-color);


            }
        }

        .Button {
            height: 43px;
            border-radius: 0;
            border: none;

            :global(.fill){
                fill: var(--secondary-bg-icons-color);
            }

            &:hover{
                :global(.fill){
                    fill: var(--alternative-primary-icons-color);
                }
            }

            // Delete
            &.Delete {
                border-radius: 0 0 10px 0;
                border-right: none;
                border-bottom: none;
            }
        }

        &:hover{
            border: 1px solid var(--alternative-primary-color);

            .Description {
                color: var(--primary-text-color);
            }
        }
    }
}
