.ProjectReport {
    grid-template-rows: 40px minmax(0, 1fr);

    // Grid Column
    .Column {
        cursor: pointer;

        // Column Name
        .ColumnName {
            overflow: hidden;
        }
    }
}