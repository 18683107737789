/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap'); */
@import url('https://fonts.googleapis.com/css?family=Poppins');

* {
    font-family: 'Poppins', 'Open Sans', sans-serif;
}

*, *:before, *:after {
    box-sizing: border-box;
}

html {
    padding: 0;
    margin: 0;
    height: 100%;
    font-size: var(--text-size-x3);
    line-height: var(--text-line-height-x3);
    overflow: hidden;
    user-select: none;
}

body {
    padding: 0;
    margin: 0;
    font-weight: 400;
    height: 100%;
    background-color: var(--secondary-bg-color);
    overflow: hidden;
    font-family: 'Poppins', 'Open Sans', sans-serif;
    color: var(--primary-text-color);
}

::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
    background-color: var(--alternative-secondary-bg-color);
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: var(--secondary-button-text-color);
}

#root {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: grid;
    overflow: hidden;
}


:root {
    /* Text Sizes */
    --text-size-x1: 10px;
    --text-size-x2: 12px;
    --text-size-x3: 14px;
    --text-size-x4: 16px;
    --text-size-x5: 18px;
    --text-size-x6: 20px;
    --text-size-x7: 40px;
    --text-size-x8: 60px;

    /* Text Line Heights */
    --text-line-height-x1: 14px;
    --text-line-height-x2: 16px;
    --text-line-height-x3: 19px;
    --text-line-height-x4: 18px;
    --text-line-height-x5: 25px;
    --text-line-height-x6: 30px;
    --text-line-height-x7: 60px;
    --text-line-height-x8: 100px;
}

/* Theme */
:root {

    /* Background Colors */
    --primary-bg-color: #ffffff;
    --header-bg-color: #3B54D0;
    --secondary-bg-color: #F5F6F8;
    --dander-bg-color: #FCE6EA;
    --success-bg-color: #EAF6E7;
    --warning-bg-color: #FFF3E0;
    --popup-screen-bg-color: rgba(232, 232, 240, 0.5);
    --disable-bg-color: #EDF0F4;
    --secondary-gradient-color: linear-gradient(180deg, #FFFFFF 0%, #F0F1F3 143.32%);

    /* Text Colors */
    --primary-text-color: #0C1E2A;
    --nodes-text-color: #0C1E2A;
    --disable-text-color: #CDD3DB;
    --danger-text-color: #E40931;
    --success-text-color: #2AA40C;
    --warning-text-color: #FF9800;

    /* Icon Colors */
    --primary-bg-icons-color: #3B54D0;
    --secondary-bg-icons-color: #A5B2C4;
    --alternative-primary-icons-color: #FFFFFF;
    --success-icons-color: #00C853;
    --danger-icons-color: #E40931;
    --disable-icons-color: #CDD3DB;

    /* Alternative Colors */
    --alternative-primary-color: #3B54D0;
    --alternative-secondary-bg-color: #EAF0F5;
    --alternative-primary-text-color: #ffffff;
    --alternative-secondary-text-color: #C6CAF8;

    /*Sidebar Colors*/
    --sidebar-primary-color: #ffffff;
    --sidebar-hover-color: #F5F6F8;
    --sidebar-primary-text-color: #3B54D0;
    --sidebar-text-color: #A5B2C4;

    /* Button - Primary */
    --primary-button-bg-color: #3B54D0;
    --primary-button-text-color: #FFFFFF;
    --primary-button-hover-text-color: #ffffff;
    --primary-button-active-color: linear-gradient(74.59deg, #2731D0 43.14%, #5C46CE 95.74%);

    /* Button - Secondary */
    --secondary-button-bg-color: #ffffff;
    --secondary-button-text-color: #3B54D0;
    --secondary-button-border-color: #EAF0F5;
    --secondary-button-hover-color: #3B54D0;
    --secondary-button-hover-text-color: #FFFFFF;
    --secondary-button-active-color: linear-gradient(74.59deg, #2731D0 43.14%, #5C46CE 95.74%);

    /* Button - Danger */
    --danger-button-bg-color: #E40931;
    --danger-button-text-color: #FFFFFF;
    --danger-button-bg-hover-color: #C8062A;

    /* Button - Success */
    --success-button-bg-color: #00C853;
    --success-button-text-color: #FFFFFF;

    /* Button - Warning */
    --warning-button-bg-color: #ffa726;

    /* Button - Disable */
    --disable-button-bg-color: #F5F6F8;
    --disable-button-text-color: #CDD3DB;
    --disable-button-border-color: #CDD3DB;

    /*Gradient Colors*/
    --primary-gradient-color: linear-gradient(74.59deg, #2731D0 43.14%, #5C46CE 95.74%);
    --secondary-gradient-color: linear-gradient(180deg, #FFFFFF 0%, #F0F1F3 143.32%);

    /* Nodes */
    --additional-blue-light: #BBDEFB;
    --additional-blue: #2962FF;
    --additional-yellow-light: #FFCC80;
    --additional-yellow: #FFA726;
    --additional-violet-light: #D3BAFF;
    --additional-violet: #7C4DFF;
    --additional-green-light: #B9F6CA;
    --additional-green: #00E676;
    --additional-red-light: #f6b9b9;
    --additional-red: #e60049;
    --additional-cyan-light: #8feeee;
    --additional-cyan: #14c9c9;

    /* Background Images */
    --auth-background-image: #FFFFFF;

    /* Shadows */
    --node-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);

    /* Other (missing from style palette) */
    --primary-border-color: #8A9EA8;
    --main-border-color: #EAF0F5;

    /* Table */
    --rdg-color: #ddd;
    --rdg-border-color: #444;
    --rdg-summary-border-color: #555;
    --rdg-background-color: #212121;
    --rdg-header-background-color: #1b1b1b;
    --rdg-row-hover-background-color: #171717;
    --rdg-row-selected-background-color: #1a73bc;
    --row-selected-hover-background-color: #1768ab;
    --rdg-checkbox-color: #94cfff;
    --rdg-checkbox-focus-color: #c7e6ff;
    --rdg-checkbox-disabled-border-color: #000;
    --rdg-checkbox-disabled-background-color: #333;
}

a{
    color: var(--primary-text-color);
    text-decoration: underline;
}

/* Splitter */
.p-splitter{
    background: var(--sidebar-primary-color);
    border-radius: 0;
    color: var(--primary-text-color);
}

.p-splitter-panel{
    overflow: hidden;
}

.p-splitter-panel > div{
    width: 100%
}

.p-splitter .p-splitter-gutter{
    background: var(--secondary-bg-color);
}

.p-splitter .p-splitter-gutter .p-splitter-gutter-handle{
    background: var(--sidebar-text-color);
}

/* React Flow */
.react-flow{
    background: var(--primary-bg-color);
}

.query-builder input[type="text"],
.query-builder input[type="text"]::placeholder,
.query-builder input[type="number"],
.query-builder input[type="number"]::placeholder,
.query-builder select,
.query-builder option {
    color: var(--primary-text-color) !important;
    background-color: var(--primary-bg-color) !important;
}

select {
    border-radius: 10px !important;
}

.rule--field select,
.rule--widget--SELECT select{
	max-width: 250px;
}

.rule--field select, .rule--operator select, .rule--widget--SELECT.widget--widget select {
    border-radius: 10px !important;
}

.tox-statusbar__branding, .tox-notifications-container {display: none;}

[contenteditable] {
    -webkit-user-select: text;
    user-select: text;
}

iframe {
    pointer-events: none;
}

.chrome-picker  {
    background-color: var(--secondary-gradient-color) !important;
}

.chrome-picker input {
    color: var(--primary-text-color) !important;
    background-color: var(--secondary-gradient-color) !important;
}

.react-datepicker__triangle {
    transform: translate(173px, 0px) !important;
}

.rule--widget--BOOLEAN .widget--widget input[type=radio] {
    opacity: 1 !important;
    z-index: 1 !important;
    position: relative !important;
}

.react-datepicker-wrapper {
    display: flex;
    flex-grow: 1;
}
