.InfoPanel {

    .Title {
        color: var(--sidebar-primary-text-color)
    }

    .Text {
        color: var(--sidebar-text-color);
    }
}
