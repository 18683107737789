.FlowV11 {
    transition: all 1s ease-out;
    // position: relative;
    background-color: var(--secondary-bg-color);

    // Fullscreen Mode
    &[data-fullscreen="true"] {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 11;
    }
}

.FlowArea{
	&.Disabled{
		cursor: progress;

		&:after{
			content: '';
			display: block;
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 10;
		}
	}
}
