.ModuleReports {
    width: 100%;

    // Range Input
    .RangeInput {
        width: 100px;
    }

    // Report Label
    .Label {
        padding-bottom: 0;
        padding-right: .5rem;
        font-size: var(--text-size-x3);
    }

    // Color Selector
    .ColorSelector {
        width: 113px;
        height: 32px;
        background-color: var(--primary-bg-color);
        cursor: pointer;
        border: 1px solid var(--sidebar-text-color);
        border-radius: 10px;

        .ColorSelectorText{
            color: var(--primary-text-color);

            &.ColorSelectorTextInactive{
                color: var(--sidebar-text-color);
            }
        }
    }

    // Close Icon
    .CloseIcon {

        :global(.fill){
            fill: var(--secondary-bg-icons-color);
        }
    }
}

.ReportRow{
    background-color: var(--secondary-bg-color);
    border: 1px solid var(--disable-text-color);
    border-radius: 10px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.06), 0 1px 2px rgba(0, 0, 0, 0.06);
}

.Label{
    color: var(--sidebar-primary-text-color);
}