.ThemeSwitcher{
	.Panel{
		background: var(--sidebar-primary-color);
		border-radius: 24px;
		cursor: pointer;

		.Icon{
			:global(.fill){
				fill: var(--secondary-bg-icons-color);
			}
		}

		.Label{
			color: var(--sidebar-text-color);
		}
	}

	&[data-theme='light'] .Panel:first-child,
	&[data-theme='dark'] .Panel:last-child{
		background: var(--sidebar-hover-color);

		.Icon{
			:global(.fill){
				fill: var(--primary-bg-icons-color);
			}
		}

		.Label{
			color: var(--sidebar-primary-text-color);
		}
	}
}