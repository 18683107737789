.InputBlock {
    min-width: 125px;
    width: 100%;

    // Label
    .Label {

        // Text
        .LabelText {
            color: var(--alternative-primary-color);
        }
    }

    // Input Wraper
    .InputWrap {
        background-color: var(--primary-bg-color);
        border: 1px solid var(--alternative-secondary-bg-color);
        border-radius: 10px;

        // Input
        .Input {
            padding: .25rem 0 .25rem 15px;
            background-color: transparent;
            border: none;
            color: var(--primary-text-color);
            min-height: 32px;
            outline: none;
            flex-grow: 1;
            font-size: var(--text-size-x2);
            line-height: var(--text-line-height-x2);
            width: 100%;

            // Hide Arrows
            -moz-appearance: textfield;
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            // Placeholder
            &:placeholder-shown {
                border-color: var(--alternative-secondary-bg-color);
            }
            &::-webkit-input-placeholder {
                color: var(--sidebar-text-color);
            }
            &::-moz-placeholder {
                color: var(--sidebar-text-color);
            }
            &:-ms-input-placeholder {
                color: var(--sidebar-text-color);
            }
            &:-moz-placeholder {
                color: var(--sidebar-text-color);
            }
        }

        // ClearButton
        .ClearButton {
            cursor: pointer;
            color: var(--secondary-bg-icons-color);

            // Inactive
            &[data-active="false"] {
                visibility: hidden;
                pointer-events: none;
            }
        }

        // NumberControls
        .NumberControls {
            padding: 0 .5rem;

            .Arrow {
                cursor: pointer;
                color: var(--secondary-bg-icons-color);

                &:hover {
                    color: var(--primary-bg-icons-color);
                }
            }
        }
    }
}