@import "./NodeInputsPositions.module.scss";
@import "./NodeOutputsPositions.module.scss";

.Node {
    padding: 0 1.1rem;
    height: 48px;
    border-radius: 10px;
    position: relative;
    box-shadow: var(--node-shadow);
    border: 2px solid;

    // Status
    .Status {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(50%, -50%);
        background-color: var(--primary-bg-color);

        svg {
            animation: none;
            transition: none;
        }
    }

    // Inputs
    .Inputs {
        position: absolute;
        top: -2px;
        left: 0;
        right: 0;

        .Input {
            background-color: var(--disable-text-color);
            width: 12px;
            height: 12px;
            top: -6px;
            transform: translateX(-50%);
        }

        // Node Inputs Position
        @include node-inputs-positions;
    }

    // Outputs
    .Outputs {
        position: absolute;
        top: calc(100% + 2px);
        left: 0;
        right: 0;

        // Output
        .Output {
            background: var(--disable-text-color);
            width: 12px;
            height: 12px;
            bottom: -6px;
            transform: translateX(-50%);
        }

        // Node Outputs Position
        @include node-outputs-positions;
    }

    // Indicator
    .Indicator{
        width: 12px;
        height: 12px;
        border-radius: 50%;
        margin-right: 0.5rem;
    }

    // Label Type
    .LabelType{
        margin-right: 0.25rem;
        color: var(--nodes-text-color);
    }

    // Label
    .Label{
        color: var(--nodes-text-color);
    }

	.Tooltip{
		margin-left: 5px;
		background: var(--secondary-button-bg-color);
		border-radius: 20px;
		height: 20px;
		width: 20px;
	}

    // Source Node
    &[data-nodetype="source"] {
        background-color: var(--additional-blue-light);
        border-color: var(--additional-blue-light);

        .Indicator{
            background-color: var(--additional-blue);
        }
    }

    // Destination Node
    &[data-nodetype="destination"] {
        background-color: var(--additional-yellow-light);
        border-color: var(--additional-yellow-light);

        .Indicator{
            background-color: var(--additional-yellow);
        }
    }

    // Function Node
    &[data-nodetype="function"],
    &[data-nodetype="functionRuleset"] {
        background-color: var(--additional-violet-light);
        border-color: var(--additional-violet-light);

        .Indicator{
            background-color: var(--additional-violet);
        }
    }

    // My Node
    &[data-nodetype="my"] {
        background-color: var(--additional-green-light);
        border-color: var(--additional-green-light);

        .Indicator {
            background-color: var(--additional-green);
        }

        // My + Health
        &[data-moduletype="health"] {
            background-color: var(--primary-bg-color);
            border-color: var(--additional-red-light);

            .Indicator {
                background-color: var(--additional-red);
            }

            .LabelType {
                color: var(--primary-text-color);
            }

            .Label {
                color: var(--primary-text-color);
            }
        }

        // My + Clinical Research
        &[data-moduletype="clinicalResearch"] {
            background-color: var(--primary-bg-color);
            border-color: var(--additional-cyan);
            color: var(--primary-text-color) !important;

            .Indicator {
                background-color: var(--additional-cyan);
            }

            .LabelType {
                color: var(--primary-text-color);
            }

            .Label {
                color: var(--primary-text-color);
            }
        }
    }

    // Ruleset Node
    &[data-nodetype="ruleset"] {
        background-color: var(--additional-violet-light);
        border-color: var(--additional-violet-light);

        .Indicator{
            background-color: var(--additional-violet);
        }
    }

    // Open Ruleset Node
    &[data-nodetype="openRuleset"] {
        background-color: var(--additional-blue-light);
        border-color: var(--additional-blue-light);

        .Indicator{
            background-color: var(--additional-blue);
        }
    }

    // Company Ruleset Node
    &[data-nodetype="companyRuleset"] {
        background-color: var(--additional-red-light);
        border-color: var(--additional-red-light);

        .Indicator{
            background-color: var(--additional-red);
        }
    }

    // My Ruleset Node
    &[data-nodetype="myRuleset"] {
        background-color: var(--additional-green-light);
        border-color: var(--additional-green-light);

        .Indicator{
            background-color: var(--additional-green);
        }
    }

    // Logic Node
    &[data-nodetype="logic"] {
        background-color: var(--additional-green-light);
        border-color: var(--additional-green-light);

        .Indicator{
            background-color: var(--additional-green);
        }
    }

    // Health Node
    &[data-nodetype="health"] {
        background-color: var(--additional-red-light);
        border-color: var(--additional-red-light);

        .Indicator{
            background-color: var(--additional-red);
        }
    }

    // Clinical Research Node
    &[data-nodetype="clinicalResearch"] {
        background-color: var(--additional-cyan);
        border-color: var(--additional-cyan);

        .Indicator {
            background-color: var(--additional-cyan-light);
        }
    }

    &.Progress{
        border-color: var(--primary-button-bg-color);

        .Status {

            svg {
                animation: rotatingProgressStatus 3s linear infinite;
            }

            :global(.fill){
                fill: var(--primary-bg-icons-color);
            }
        }
    }

    &.Success{
        border-color: var(--success-button-bg-color);

        .Status {
            :global(.fill){
                fill: var(--success-icons-color);
            }
        }
    }

    &.Reject{
        border-color: var(--danger-button-bg-color);

        .Status {
            :global(.fill){
                fill: var(--danger-icons-color);
            }
        }

        & .NodeBox{
            opacity: 0.4;
        }
    }

    .ControlPanel{
        background: var(--primary-bg-color);
        border: 1px solid var(--alternative-primary-color);
        border-radius: 10px;

        position: absolute;
        top: 0;
        right: calc(50% + 0.75rem);
        transform: translateY(calc(-100% - 1rem));

        .ControlBtn{
            width: 40px;
            height: 40px;
            cursor: pointer;

            color: var(--secondary-bg-icons-color);

            &:hover {
                color: var(--primary-bg-icons-color);
            }
        }

    }
}

@keyframes rotatingProgressStatus {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(-360deg);
    }
}
