.Counter {

    // Minus
    .Minus, .Plus {
        .Button {
            height: auto;
            color: var(--secondary-bg-icons-color);
        }
    }

    // Amount
    .Amount {
        color: var(--primary-text-color);
        background-color: var(--primary-bg-color);
        border: 1px solid var(--sidebar-text-color);
        border-radius: 10px;
        font-size: var(--text-size-x2);
        line-height: var(--text-line-height-x2);
        width: 44px;
        height: 24px;
        text-align: center;
    }

    // Plus
    .Plus {}
}
