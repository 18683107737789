.RulesetLayout {

    // Top Bar
    .TopBar {
        padding: 1rem;
    }
}

.RulesetLayoutHeader {
    background-color: var(--secondary-bg-color);
}