.AddNodesToFlowModal {
	top: 60px;

	.TopBar {
		grid-template-columns: 1fr 1fr;
		background-color: tomato;
	}

	// Filter
	.Filter {
		background-color: transparent;
		// border-bottom: 2px solid var(--secondary-button-text-color);
		// padding-top: 1rem;
	}

	// Grid
	.Grid {
		grid-template-columns: repeat(4, minmax(175px, 1fr));
		gap: 1rem;
		margin-top: 1rem;
		margin-bottom: 1rem;
		padding-right: .5rem;

		// Cell
		.Cell {
			background: var(--secondary-gradient-color);
			border: 1px solid var(--secondary-button-border-color);
			border-radius: 10px;


			// MoreMenuItem
			.MoreMenuItem {
				// color: var(--sidebar-text-color);
				justify-content: flex-start;
				padding-left: 1rem;
				padding-right: 1rem;
				flex-grow: 1;

				// &:hover {
				// 	color: var(--alternative-primary-color);
				// }
			}

			// Name
			.Name {
				padding: .5rem;
				min-height: 2.5rem;
			}

			.Icon{
				:global(.fill) {
					fill: var(--primary-text-color);
				}
				:global(.stroke) {
					stroke: var(--primary-text-color);
				}
			}

			// Module Type
			.ModuleType {
				color: var(--sidebar-text-color);
				padding: .25rem;
				font-size: 12px;
			}

			// Description
			.Description {
				padding: .5rem;
				height: 93px;
			}

			// Footer
			.Footer {
				background-color: var(--primary-bg-color);
				border-bottom-left-radius: 10px;
				border-bottom-right-radius: 10px;
				padding: .5rem;

				// Optimize
				.Optimize {
					color: var(--secondary-bg-icons-color);

					&.Active {
						color: var(--success-icons-color);
						pointer-events: none;
					}

					// Hover
					&:hover:not(.Active) {
						color: var(--primary-bg-icons-color);
					}
				}
			}
		}
	}

	// List
	.List {
		height: 50vh;
		overflow-y: auto;
		margin-top: 1rem;

		// List Item
		.ListItem {
			cursor: pointer;
			background-color: var(--primary-bg-color);
			color: var(--sidebar-text-color);
			border-radius: 10px;
			margin-bottom: 10px;
			border: 1px solid var(--secondary-button-border-color);

			// Icon fill
			:global(.fill) {
				fill: var(--secondary-bg-icons-color);
			}

			.ItemNameWrapper {
				overflow: hidden;
			}

			.ItemName {
				color: var(--sidebar-text-color);
				line-height: 18px;
			}

			// Hover State
			&:hover {
				background-color: var(--sidebar-hover-color);
				border-color: var(--secondary-button-hover-color);

				.ItemName {
					color: var(--sidebar-primary-text-color);
				}
			}
		}
	}
}
