.AuthPage {
    position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
    background-color: rgba(232, 232, 240, 0.5);
	background-image: var(--auth-background-image);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
    z-index: 5;

	//Form
    .AuthForm {
		.AuthInputs{
			width: 475px;

			.AuthInputLabel{
				color: var(--primary-text-color);
				font-size: var(--text-size-x3);
				line-height: var(--text-line-height-x3);
			}

			.AuthInput{
				margin: 0;
				padding: .75rem 2rem .75rem 1rem!important;
				font-size: var(--text-size-x3);
				line-height: var(--text-line-height-x3);
			}
		}

		.AuthLinkForgot{
			color: var(--sidebar-primary-text-color);
		}

		.AuthLinkSignUp{
			margin-left: 3px;
			color: var(--sidebar-primary-text-color);
			text-decoration: none;
		}
    }
}