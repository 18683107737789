.Box{
    width: 100%;
}

.Search{
	width: 250px;
}

.SearchInput{
	&.SearchActive{
		border-color: var(--alternative-primary-color)!important;
		background-color: var(--secondary-bg-color)!important;
	}
}
