.Box{
    width: 100%;
}

.Header{
    overflow: hidden;

    .Title{
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .Score{
        background-color: var(--alternative-secondary-bg-color);
        min-width: 25px;
        height: 25px;
        border-radius: 100%;
        margin-right: 10px;

        .ScoreText{
            color: var(--sidebar-primary-text-color);
        }
    }
}