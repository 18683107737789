.MoreMenu {
    position: relative;
    z-index: 1;

    // ActionButton
    .ActionButton {
        height: auto;
        color: var(--secondary-bg-icons-color);
    }

    // Items
    .Items {
        position: absolute;
        top: 0;
        right: 0;
        background-color: var(--primary-bg-color);
        border: 1px solid var(--alternative-primary-color);
        border-radius: 10px;
        white-space: nowrap;

        // Item
        .Item {

            // First Child
            &:first-child {
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
            }

            // Last Child
            &:last-child {
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
            }

            // Hover
            &:hover {
                background-color: var(--secondary-bg-color);
            }
        }
    }
}

.MoreMenuOverlay {
    position: fixed;
    inset: 0;
    background-color: transparent;
}
