@mixin node-inputs-positions {
    
    // 2 Inputs
    &[data-inputs="2"] {

        // #1
        .Input:nth-child(1) {
            left: 33%;
        }

        // #2
        .Input:nth-child(2) {
            // left: auto;
            left: 66%;
        }
    }

    // 3 Inputs
    &[data-inputs="3"] {

        // #1
        .Input:nth-child(1) {
            left: 25%;
        }

        // #2
        .Input:nth-child(2) {
            left: 50%;
        }

        // #3
        .Input:nth-child(3) {
            left: 75%;
        }
    }
}