.MenuProfileWrapper{
	position: relative;
}

.MenuProfile{
	cursor: pointer;

	.ProfileName,
	.ProfilePosition{
		color: var(--alternative-primary-text-color);
	}

	// Profile Icon
	.ProfileIcon{
		:global(.fill){
			fill: var(--secondary-bg-icons-color);
		}
	}

	// Arrow Icon
	.ArrowIcon{
		:global(.fill){
			fill: var(--secondary-bg-icons-color);
		}
	}
}

.DropdownLayer{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 6;
}

.Dropdown{
	position: absolute;
	top: 60px;
	left: 0;
	right: 0;
	border: 1px solid var(--sidebar-primary-text-color);
	border-top: none;
	background: var(--sidebar-primary-color);
	z-index: 7;
	min-width: 220px;
}

.DropdownLink{
	text-decoration: none;
}

.DropdownItem{
	background: var(--sidebar-primary-color);
	cursor: pointer;

	.DropdownIcon{
		svg{
			flex-shrink: 0;
		}

		:global(.fill){
			fill: var(--secondary-bg-icons-color);
		}
	}

	.DropdownLabel{
		color: var(--sidebar-text-color);
	}

	&:hover,
	&:active{
		background: var(--sidebar-hover-color);

		:global(.fill){
			fill: var(--primary-bg-icons-color);
		}

		.DropdownLabel{
			color: var(--sidebar-primary-text-color);
		}
	}
}