.Edge{
	stroke: #b1b1b7;
	stroke-width: 1;
	fill: none;
}

.EdgeDelete {
	cursor: pointer;

	:global(.fill) {
		fill: var(--secondary-bg-icons-color);
	}

	&:hover {
		:global(.fill) {
			fill: var(--danger-icons-color);
		}
	}
}