.Drawer {
    display: grid;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--sidebar-primary-color);
    grid-template-rows: 60px 1fr auto;
    transition: transform .75s cubic-bezier(0.68, -0.6, 0.32, 1.6);
    z-index: 1;

    // Inactive Anchor
    &[data-open="false"] {
        &[data-anchor="left"] { transform: translateX(-100%); }
        &[data-anchor="right"] { transform: translateX(100%); }
        &[data-anchor="top"] { transform: translateY(-100%); }
        &[data-anchor="bottom"] { transform: translateY(100%); }
    }

    // Active Anchor
    &[data-open="true"] {
        &[data-anchor="left"] { transform: translateX(0); }
        &[data-anchor="right"] { transform: translateX(0); }
        &[data-anchor="top"] { transform: translateY(0); }
        &[data-anchor="bottom"] { transform: translateY(0); }
    }

    // Header
    .Header {
        border-bottom: 1px solid var(--alternative-secondary-bg-color);
        color: var(--secondary-bg-icons-color);

        // CloseIcon
        .CloseIcon {
            color: var(--secondary-bg-icons-color);
        }
    }

    // Content
    .Content {
        padding: 1rem;
    }

    // Footer
    .Footer {
        height: 60px;
    }
}

.DrawerOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--sidebar-primary-color);
    z-index: 1;
    transition: opacity .75s cubic-bezier(0.68, -0.6, 0.32, 1.6);

    // Inactive Anchor
    &[data-open="false"] {
        opacity: 0;
    }

    // Active Anchor
    &[data-open="true"] {
        opacity: 1;
    }
}
