.Dropdown {
	white-space: nowrap;
	// min-height: 32px;

	&.hasMaxWidth{
		max-width: 300px;
	}

	// Disabled
	[data-disabled="true"] {
		opacity: .5;
		pointer-events: none;
	}

	// Type Button
	&[data-type="button"] {
		background: var(--secondary-button-bg-color);
		border: 1px solid var(--secondary-button-border-color);
		border-radius: 12px;
		min-height: 38px;
		justify-content: center;
		align-items: center;
		cursor: pointer;

		&.active{
			background: var(--secondary-button-hover-color);
			border: 1px solid var(--secondary-button-hover-color);

			.Icon {
				:global(.fill) {
					fill: var(--alternative-primary-icons-color);
				}
			}
		}
	}

	// Type Button Simplified
	&[data-type="button-simplified"]{
		min-height: 38px;
		justify-content: center;
		align-items: center;
		cursor: pointer;

		&.active{
			.Icon {
				:global(.fill) {
					fill: var(--primary-bg-icons-color);
				}
			}
		}
	}

	// Type Simple
	&[data-type="simple"]{
		min-height: 24px;
		justify-content: center;
		align-items: center;
		cursor: pointer;

		.Icon {
			padding-right: 10px;
		}

		&.active{
			.Icon {
				:global(.fill) {
					fill: var(--primary-bg-icons-color);
				}
			}
		}

		// Arrow Icon
		.ArrowIcon {
			width: 24px;
			height: 24px;

			svg{
				width: 24px;
				height: 24px;
			}

			:global(.fill) {
				fill: var(--primary-bg-icons-color);
			}
		}
	}

	// Label
	.Label {
		padding-bottom: .5rem;

		// Text
		.LabelText {
			color: var(--alternative-primary-color);
		}
	}

	// Icon
	.Icon {
		:global(.fill) {
			fill: var(--primary-bg-icons-color);
		}
	}

	// Arrow Icon
	.ArrowIcon {
		width: 14px;
		height: 14px;
		transition: transform .2s;

		:global(.fill) {
			fill: var(--secondary-bg-icons-color);
		}

		&.ArrowIconActive{
			transform: rotate(-180deg);
		}
	}
}

.SelectRoot{
	min-width: 210px;
	padding: 5px 15px;
	border: 1px solid var(--alternative-secondary-bg-color);
	background: var(--primary-bg-color);
	border-radius: 10px;
	cursor: pointer;

	// Small Size
	&[data-size="small"] {
		height: 16px;
	}
	// Middle Size
	&[data-size="middle"] {
		height: 26px;
	}
	// Large Size
	&[data-size="large"] {
		height: 32px;
	}

	// Type Button
	.Dropdown[data-type="button"] &{
		border: none;
		padding: 0 0 0 10px;
		height: 36px;
		min-width: unset;

		.SelectText{
			.SelectPlaceholder{
				color: var(--secondary-button-text-color);
			}
		}
	}

	.Dropdown[data-type="button"].active &{
		background: var(--secondary-button-hover-color);

		.SelectText{
			.SelectPlaceholder{
				color: var(--secondary-button-hover-text-color);
			}
		}
	}

	// Type Button Simplified
	.Dropdown[data-type="button-simplified"] &{
		border: none;
		background: transparent;
		padding: 0 0 0 10px;
		height: 36px;
		min-width: unset;

		.SelectText{
			.SelectPlaceholder{
				color: var(--sidebar-primary-text-color);
			}
		}
	}

	.Dropdown[data-type="button-simplified"].active &{
		background: var(--sidebar-primary-text-color);

		.SelectText{
			.SelectPlaceholder{
				color: var(--sidebar-primary-text-color);
			}
		}
	}

	// Type Button Simplified
	.Dropdown[data-type="simple"] &{
		border: none;
		background: transparent;
		padding: 0;
		height: 24px;
		min-width: unset;

		.SelectText{
			.SelectPlaceholder{
				color: var(--sidebar-primary-text-color);
			}

			.SelectValue{
				color: var(--alternative-primary-color);
			}
		}
	}

	.Dropdown[data-type="simple"].active &{
		background: var(--sidebar-primary-text-color);

		.SelectText{
			.SelectPlaceholder{
				color: var(--sidebar-primary-text-color);
			}

			.SelectValue{
				color: var(--alternative-primary-color);
			}
		}
	}

	.SelectText{
		.SelectPlaceholder{
			color: var(--sidebar-text-color);
		}

		.SelectValue{
			color: var(--primary-text-color);
		}
	}

}

.SelectWrapper{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 15;

	.SelectBg{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

.Select{
	position: absolute;
	top: 0;
	left: 0;
	background: var(--primary-bg-color);
	border: 1px solid var(--alternative-primary-color);
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
	border-radius: 10px;
	overflow: hidden;
	min-width: 210px;
	max-height: 370px;

	.NotFound{
		padding: 9px 0;
		width: 100%;
		text-align: center;
		color: var(--alternative-primary-color);
	}

	.Option{
		padding: 9px;
		cursor: pointer;
		width: 100%;

		.OptionText{
			color: var(--primary-text-color);
		}

		&.OptionActive,
		&:hover{
			background: var(--secondary-bg-color);

			.OptionText{
				color: var(--alternative-primary-color);
			}
		}
	}

	.SubHead{
		padding: 9px;
		width: 100%;

		.SubHeadText{
			color: var(--alternative-primary-color);
		}
	}
}
