@mixin node-outputs-positions {

    // 1 Output
    &[data-outputs="1"] {

        // #1
        .Output:nth-child(1) {
            left: 50%;
        }
    }
    
    // 2 Outputs
    &[data-outputs="2"] {

        // #1
        .Output:nth-child(1) {
            left: 33%;
        }

        // #2
        .Output:nth-child(2) {
            left: 66%;
        }
    }

    // 3 Outputs
    &[data-outputs="3"] {

        // #1
        .Output:nth-child(1) {
            left: 25%;
        }

        // #2
        .Output:nth-child(2) {
            left: 50%;
        }

        // #3
        .Output:nth-child(3) {
            left: 75%;
        }
    }
}